import React, { useCallback, useMemo } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Stack,
  Typography
} from '@mui/material';
import { IEvent } from '../../interface';
import { PermMedia, VideoLibrary, Visibility } from '@mui/icons-material';
import { fShortenNumber } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SET_ACTIVE_EVENT } from '../../../store/actions/eventActions';

export const noEventCoverImageAvailableUrl =
  'https://via.placeholder.com/150?text=No+Image+Added';

interface IProps {
  event: IEvent;
}

const EventCard: React.FC<IProps> = ({ event }: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const eventStatus: {
    status: string;
    color: 'primary' | 'success' | 'error';
  } = useMemo(() => {
    if (event.status === 'INITIAL') {
      return {
        status: 'Upcoming',
        color: 'primary'
      };
    }
    if (event.status === 'STARTED') {
      return {
        status: 'Live Now',
        color: 'success'
      };
    }
    return {
      status: 'Ended',
      color: 'error'
    };
  }, [event]);

  const handleViewDetails = useCallback(() => {
    if (event) {
      dispatch({
        type: SET_ACTIVE_EVENT,
        payload: { event }
      });
      navigate(`/dashboard/event/${event.uuid}`);
    }
  }, [dispatch, navigate, event]);

  const formattedDate = useMemo(() => {
    const date = new Date(event.event_date);
    return new Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    }).format(date);
  }, [event.event_date]);

  return (
    <Card sx={{ minWidth: 275 }} className="flex flex-col">
      <CardMedia
        component="img"
        image={event.image || noEventCoverImageAvailableUrl}
        sx={{ height: 150, objectFit: 'cover' }}
        alt="Event Image"
      />
      <CardContent className="pb-1">
        <Typography
          className="text-nowrap pr-2 overflow-x-hidden text-ellipsis"
          variant="h6"
          color="text.secondary"
          gutterBottom
        >
          {event.name}
        </Typography>
        <Typography variant="body2" color="text.disabled" sx={{ mb: 1.5 }}>
          {formattedDate}
        </Typography>
        <Stack
          direction="row"
          sx={{
            mb: 2,
            width: '100%',
            justifyContent: 'space-between'
          }}
        >
          <Chip
            size="small"
            label={event.live_mode ? 'Published Mode' : 'Testing Mode'}
            color={event.live_mode ? 'success' : 'warning'}
            variant="outlined"
            sx={{ fontWeight: 500 }}
          />
          <Chip
            label={eventStatus.status}
            color={eventStatus.color}
            variant="filled"
            size="small"
          />
        </Stack>
        <Stack
          direction="row"
          className="d-flex flex-row flex-nowrap align-middle justify-between"
        >
          <Typography variant="body2" color="text.secondary" gutterBottom>
            <PermMedia sx={{ mr: 1 }} />
            {fShortenNumber(event.num_live_images)} Photos
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            <Visibility sx={{ mr: 1 }} />
            {fShortenNumber(event.views_count || 0)} Views
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            <VideoLibrary sx={{ mr: 1 }} /> 0 Videos
          </Typography>
        </Stack>
      </CardContent>
      <CardActions className="mt-auto" sx={{ p: 2 }}>
        <Button
          size="medium"
          variant="contained"
          className="capitalize"
          fullWidth
          onClick={handleViewDetails}
          sx={{
            borderRadius: '8px',
            textTransform: 'none',
            fontWeight: 500,
            py: 1,
            backgroundColor: 'primary.main',
            '&:hover': {
              backgroundColor: 'primary.dark',
              transform: 'translateY(-1px)',
              transition: 'transform 0.2s'
            }
          }}
        >
          View Event
        </Button>
      </CardActions>
    </Card>
  );
};

export default EventCard;
