import React, { useEffect } from 'react';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import 'react-phone-input-2/lib/style.css';
import useSWR from 'swr';
import { API_CONFIG, swrOptions } from '../../shared/constants/constants';
import { Dispatch } from 'redux';
import httpService from '../../shared/services/http.service';
import SubscriptionPackagesSkeleton from './page-loader';
import {
  fetchCompanyDetails,
  SET_USER_CLIENT_SECRET,
  SET_USER_SUBSCRIPTION_COMPLETE
} from '../../store/actions/authActions';
import {
  ISubscriptionPackage,
  NotificationType,
  State
} from '../../shared/interface';
import { useLocation } from 'react-router-dom';
import actionTypes from '../../store/action-types';

const styleCurrentPackage = {
  outline: '#260155',
  color: '#260155'
};
export const fetchPackages = async (
  dispatch?: Dispatch,
  showLoader = false
) => {
  return await httpService
    .get(API_CONFIG.PATH.PACKAGES.GET)(dispatch, showLoader)
    .then(data => data);
};

const Subscriptions: React.FC = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const completed = query.get('completed');
  const dispatch = useDispatch();
  const { company } = useSelector((state: State) => state.auth.user);
  const { data: packages, isLoading } = useSWR(
    API_CONFIG.PATH.PACKAGES,
    () => fetchPackages(),
    swrOptions
  );

  useEffect(() => {
    if (completed) {
      dispatch({
        type: SET_USER_SUBSCRIPTION_COMPLETE,
        payload: true
      });
    }
  }, [completed, dispatch]);

  const handlePackageSelection = async (packageItem: {
    uuid: string;
    price: string;
  }) => {
    try {
      const response = await httpService.post(API_CONFIG.PATH.PACKAGES.CREATE, {
        package_id: packageItem.uuid,
        payment_method: 'card'
      })(dispatch);

      if (response?.redirect_url) {
        // Open payment URL in new tab
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        window.open(response.redirect_url, '_blank');
      } else {
        dispatch({
          type: actionTypes.SET_NOTIFICATION,
          payload: {
            message: 'Failed to initiate payment',
            type: NotificationType.ERROR
          }
        });
      }
    } catch (error) {
      dispatch({
        type: actionTypes.SET_NOTIFICATION,
        payload: {
          message: 'Failed to process payment request',
          type: NotificationType.ERROR
        }
      });
    }
  };

  useEffect(() => {
    if (!company.uuid) return;
    fetchCompanyDetails(dispatch).then(() => {
      if (!completed) {
        dispatch({
          type: SET_USER_CLIENT_SECRET,
          payload: null
        });
      }
    });
  }, [company.uuid, dispatch, completed]);

  const subscriptionButtonLabel = (item: ISubscriptionPackage) => {
    if (item.uuid === company.latest_subscription_package?.uuid) {
      return company.has_active_subscription
        ? 'Current Subscription'
        : 'Renew Subscription';
    }
    return `Get ${item.name}`;
  };

  if (isLoading || !packages) {
    return <SubscriptionPackagesSkeleton />;
  }

  const subscriptionHasEnded =
    !!company.latest_subscription_package?.uuid &&
    !company.has_active_subscription;

  const formatPrice = (price: string, currency: string) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency
    }).format(Number(price));
  };

  return (
    <Box
      className="flex flex-col items-center justify-center bg-gray-50"
      style={{ minHeight: '87vh' }}
    >
      {!company.latest_subscription_package?.uuid && (
        <Alert variant="outlined" severity="info">
          You need to have subscribed to a package to use your dashboard!
        </Alert>
      )}
      {subscriptionHasEnded ? (
        <Alert variant="outlined" severity="info">
          Your active subscription has ended!
        </Alert>
      ) : null}
      <Typography variant="h4" className="mb-8">
        Choose your plan
      </Typography>
      <Box className="flex space-x-4 flex-row align-middle justify-center">
        {packages?.results.map((item: ISubscriptionPackage, index: number) => (
          <Card
            key={item.uuid}
            className={
              index === 1
                ? 'max-w-sm p-4 border-2 primary-border relative'
                : 'max-w-sm p-4 border-2 border-gray-300'
            }
            style={{
              height: index === 1 ? '65vh' : '60vh',
              marginTop: index === 1 ? undefined : '3vh'
            }}
          >
            {index === 1 ? (
              // eslint-disable-next-line max-len
              <Box className="absolute top-2 right-2 bg-primary text-white px-2 py-1 rounded-full">
                Recommended
              </Box>
            ) : null}
            <CardContent>
              <Typography variant="h5" className="mb-4">
                {item.name}
              </Typography>
              <Typography variant="h6" className="mb-10">
                {formatPrice(item.price, item.currency)}
              </Typography>
              <ul className="list-disc pl-5 my-10 text-left">
                {item.description
                  .split(',')
                  .map((feature: string, i: number) => (
                    <li key={`${item.uuid}--feature--000${i + 1}`}>
                      {feature.trim()}
                    </li>
                  ))}
              </ul>
              <Button
                className={index === 1 ? 'capitalize mt-5' : 'capitalize'}
                variant={index === 1 ? 'contained' : 'outlined'}
                color={
                  company.latest_subscription_package?.uuid === item.uuid &&
                  !company.has_active_subscription
                    ? 'info'
                    : 'primary'
                }
                fullWidth
                onClick={() => handlePackageSelection(item)}
                disabled={
                  company.latest_subscription_package?.uuid === item.uuid &&
                  company.has_active_subscription
                }
                style={
                  company.latest_subscription_package?.uuid === item.uuid &&
                  company.has_active_subscription
                    ? styleCurrentPackage
                    : {}
                }
              >
                {subscriptionButtonLabel(item)}
              </Button>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default Subscriptions;
