import React, { useMemo, useState } from 'react';
import {
  Alert,
  Badge,
  Box,
  FormControlLabel,
  Switch,
  Tab,
  Tabs,
  Button,
  IconButton,
  Tooltip
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LockIcon from '@mui/icons-material/Lock';

import { Link, Outlet, useLocation } from 'react-router-dom';
import EventActionMenu from './workspaces/action-menu';
import EventImageUploadStatus from './workspaces/upload-staus';
import EventWorkSpace from './workspaces/content-view-drawer';
import { useDispatch, useSelector } from 'react-redux';
import { IEvent, NotificationType, State } from '../../../shared/interface';
import EventDetailsWorkspace from './workspaces/event-details-workspace';
import {
  SET_ACTIVE_EVENT,
  SET_ACTIVE_EVENT_WORKSPACES
} from '../../../store/actions/eventActions';
import httpService from '../../../shared/services/http.service';
import { API_CONFIG } from '../../../shared/constants/constants';
import actionTypes from '../../../store/action-types';
import { alpha } from '@mui/material/styles';
import LivestreamSubscriptionModal from './stream/LivestreamSubscriptionModal';

const EventDetails: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { openWorkspace, event: eventDetails } = useSelector(
    (state: State) => state.event.activeEvent
  );
  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false);
  const TEST_APK_URL =
    'https://drive.google.com/file/d/1COXBC1bHbq8qLJLChvXjuRAwhwsIgpGq/view?usp=sharing';

  const selectedTab = useMemo(() => {
    if (location.pathname.includes('photos')) return 0;
    if (location.pathname.includes('videos')) return 1;
    if (location.pathname.includes('stream')) return 2;
    if (location.pathname.includes('mini-statements')) return 3;
    return 0;
  }, [location.pathname]);

  const [photosCount] = useState(0);
  const [videosCount] = useState(0);
  const [liveStreamCount] = useState(0);
  const [miniStatementsCount] = useState(0);

  const uploadContentType = useMemo(() => {
    if (selectedTab === 0) return 'Images';
    if (selectedTab === 1) return 'Videos';
    return 'Images';
  }, [selectedTab]);

  const isLiveStreamDisabled = !eventDetails?.is_live_subscribed;

  const handleChangeLiveMode = async () => {
    if (!eventDetails) return;
    try {
      const response: IEvent = await httpService.patch(
        API_CONFIG.PATH.EVENTS.EDIT(eventDetails.uuid),
        {
          live_mode: !eventDetails.live_mode
        },
        {},
        { contentType: 'multipart/form-data' }
      )(dispatch, true);
      dispatch({
        type: actionTypes.SET_NOTIFICATION,
        payload: {
          message: `Event ${eventDetails.name} live mode was updated successfully`,
          type: NotificationType.SUCCESS
        }
      });
      dispatch({
        type: SET_ACTIVE_EVENT,
        payload: { event: response }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 2 && isLiveStreamDisabled) {
      dispatch({
        type: actionTypes.SET_NOTIFICATION,
        payload: {
          message:
            'Please subscribe to live streaming service to access this feature',
          type: NotificationType.WARNING
        }
      });
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={{ mb: 2 }}
      >
        <Box
          flexGrow={1}
          className="mr-4 pa-0"
          style={{ backgroundColor: 'organge' }}
        >
          {!eventDetails?.live_mode && (
            <Alert
              variant="outlined"
              severity="warning"
              action={
                <Tooltip
                  title="1. Enable 'Install from Unknown Sources' in your Android settings
                              2. Download the APK file
                              3. Open the downloaded file
                              4. Follow installation prompts
                              Note: Testing is only available on Android devices"
                  arrow
                  placement="bottom-end"
                >
                  <IconButton size="small" color="warning">
                    <HelpOutlineIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              }
            >
              Event is in test mode, it can only be visible to your test apk
              (Download testing APK{' '}
              <a
                href={TEST_APK_URL}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'inherit', fontWeight: 'bold' }}
              >
                here
              </a>{' '}
              and run it on your Android phone)
            </Alert>
          )}
        </Box>
        <FormControlLabel
          control={
            <Switch
              color="success"
              checked={eventDetails?.live_mode}
              onChange={handleChangeLiveMode}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Event Live Mode"
        />
      </Box>
      <Tabs value={selectedTab} onChange={handleTabChange}>
        <Tab
          label={
            <Badge badgeContent={photosCount} color="primary">
              Photos
            </Badge>
          }
          component={Link}
          to="photos"
        />
        <Tab
          label={
            <Badge badgeContent={videosCount} color="primary">
              Videos
            </Badge>
          }
          component={Link}
          to="videos"
        />
        <Tab
          label={
            <Badge badgeContent={liveStreamCount} color="primary">
              Live Stream
              {isLiveStreamDisabled && (
                <Box
                  component="span"
                  sx={{
                    ml: 1,
                    display: 'inline-flex',
                    alignItems: 'center',
                    color: 'warning.main'
                  }}
                >
                  <LockIcon sx={{ fontSize: '1rem' }} />
                </Box>
              )}
            </Badge>
          }
          component={Link}
          to="stream"
          sx={{
            ...(isLiveStreamDisabled && {
              color: 'text.disabled',
              bgcolor: theme => alpha(theme.palette.action.disabled, 0.1),
              '&:hover': {
                bgcolor: theme => alpha(theme.palette.action.disabled, 0.2)
              }
            })
          }}
        />
        <Tab
          label={
            <Badge badgeContent={miniStatementsCount} color="primary">
              Mini Statements
            </Badge>
          }
          component={Link}
          to="mini-statements"
        />
      </Tabs>
      <Box sx={{ p: 4, pt: 0, mr: 2, minHeight: '70vh', position: 'relative' }}>
        {selectedTab === 2 && isLiveStreamDisabled ? (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backdropFilter: 'blur(8px)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1
            }}
          >
            <Alert
              severity="warning"
              sx={{ maxWidth: 400 }}
              action={
                <Button
                  color="warning"
                  size="small"
                  onClick={() => setSubscriptionModalOpen(true)}
                >
                  Subscribe
                </Button>
              }
            >
              This feature is locked. Please subscribe to live streaming service
              to access it.
            </Alert>
          </Box>
        ) : null}
        <Outlet />
      </Box>
      <LivestreamSubscriptionModal
        open={subscriptionModalOpen}
        onClose={() => setSubscriptionModalOpen(false)}
        eventUuid={eventDetails?.uuid || ''}
      />
      <EventActionMenu />
      <EventImageUploadStatus contentType={uploadContentType} />
      {openWorkspace === 'event-details' && eventDetails ? (
        <EventWorkSpace
          canExpand
          title={eventDetails.name}
          backGroundColor="#F0F0F0"
          content={<EventDetailsWorkspace />}
          handleCloseDrawer={() => {
            dispatch({ type: SET_ACTIVE_EVENT_WORKSPACES, payload: null });
          }}
        />
      ) : null}
    </>
  );
};

export default EventDetails;
