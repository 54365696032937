import React from 'react';
import { Box, Typography, Grid, TextField, Alert } from '@mui/material';
import { useSelector } from 'react-redux';
import { State } from '../../../shared/interface';
import EmailIcon from '@mui/icons-material/Email';

const ReviewStep: React.FC = () => {
  const { user } = useSelector((state: State) => state.auth);
  const company = user?.company;

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Review Company Information
      </Typography>

      <Alert severity="info" sx={{ mb: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <EmailIcon />
          <Typography variant="body2">
            We are currently reviewing your application. Please check{' '}
            {user?.email} for approval status.
          </Typography>
        </Box>
      </Alert>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle2">Company Name</Typography>
          <TextField
            fullWidth
            value={company?.name || ''}
            InputProps={{ readOnly: true }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="subtitle2">Business Email</Typography>
          <TextField
            fullWidth
            value={company?.business_email || ''}
            InputProps={{ readOnly: true }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="subtitle2">Location</Typography>
          <TextField
            fullWidth
            value={company?.location || ''}
            InputProps={{ readOnly: true }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="subtitle2">Currency</Typography>
          <TextField
            fullWidth
            value={company?.currency || ''}
            InputProps={{ readOnly: true }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="subtitle2">Support Number</Typography>
          <TextField
            fullWidth
            value={company?.support_number || ''}
            InputProps={{ readOnly: true }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">Description</Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={company?.description || ''}
            InputProps={{ readOnly: true }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">Uploaded Documents</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography variant="body2" color="textSecondary">
                Registration Certificate:{' '}
                {company?.company_document_one
                  ? '✓ Uploaded'
                  : '✗ Not uploaded'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="body2" color="textSecondary">
                Memorandum:{' '}
                {company?.company_document_two
                  ? '✓ Uploaded'
                  : '✗ Not uploaded'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="body2" color="textSecondary">
                National ID:{' '}
                {company?.company_document_three
                  ? '✓ Uploaded'
                  : '✗ Not uploaded'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReviewStep;
