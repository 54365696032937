import _ from 'lodash';
import { config } from '../../config/config';

const BASE_URL = config.apiUrl;
export const STRIPE_PUBLISHABLE_KEY = config.stripeKey;
export const APP_CLIENT_ID = config.clientId;

export const API_CONFIG = {
  BASE_URL,
  PATH: {
    GOOGLE_AUTH: 'v1/users/google-auth/',
    LOGIN: 'v1/users/signin/',
    ME: 'v1/users/me/',
    COMPANY: {
      GET: 'v1/company/b2b/get-company/',
      CREATE: 'v1/company/b2b/create-company/',
      UPDATE: 'v1/company/b2b/update-company/',
      BALANCE: (companyId: string) => `ws/balance/${companyId}`
    },
    PACKAGES: {
      GET: 'v1/subscriptions/b2b/packages/',
      CREATE: 'v1/subscriptions/b2b/subscribe/',
      UPDATE: 'v1/subscriptions/b2b/subscribe/',
      FREE_TRIAL: 'v1/subscriptions/free-trial/'
    },
    PAYMENT: {
      PLATFORM_FEE: 'v1/payment-providers/platform-fee/'
    },
    EVENTS: {
      START_STOP_EVENT: (uuid: string) =>
        `v1/events/b2b/${uuid}/manage-event-status/`,
      CREATE: 'v1/events/b2b/events/',
      EDIT: (uuid: string) => `v1/events/b2b/events/${uuid}/patch/`,
      DELETE: (uuid: string) => `v1/events/b2b/events/${uuid}/delete/`,
      GET: 'v2/events/b2b/events/',
      TYPES: 'v1/events/types/',
      CREATE_STREAM: 'v1/stream/create/',
      DELETE_EVENT_VIDEO: (eventUuid: string, videoUuid: string) =>
        `v1/events/b2b/event/${eventUuid}/video/${videoUuid}`,
      DELETE_EVENT_IMAGE: (eventUuid: string, imageUuid: string) =>
        `v1/events/b2b/event/${eventUuid}/image/${imageUuid}`,
      GET_EVENT_STREAM: (uuid: string) => `v1/stream/event_uuid/${uuid}/`,
      START_STREAM: (name: string) => `v1/stream/${name}/start/`,
      STOP_STREAM: (name: string) => `v1/stream/${name}/stop/`,
      GET_EVENT_IMAGES: (uuid: string, offset = 0) =>
        `v1/events/b2b/events/${uuid}/live-images/?limit=16&offset=${offset}`,
      POST_EVENT_IMAGES: (uuid: string) =>
        `v1/events/b2b/events/${uuid}/live-images/bulk-upload/`,
      GET_EVENT_VIDEOS: (uuid: string, offset = 0) =>
        `v1/events/b2b/events/${uuid}/short-videos/?limit=16&offset=${offset}`,
      POST_EVENT_VIDEOS: (uuid: string) =>
        `v1/events/b2b/events/${uuid}/short-videos/bulk-upload/`,
      EVENT_STREAM: (eventUuid: string) =>
        `v1/events/events/${eventUuid}/stream/`,
      LIVESTREAM_SUBSCRIPTION: 'v1/events/livestream-subscription/',
      GET_MINI_STATEMENTS: (eventUuid: string) =>
        `v1/events/b2b/event/${eventUuid}/mini-statement/list/`,
      DOWNLOAD_MINI_STATEMENTS_PDF: (eventUuid: string) =>
        `v1/events/b2b/event/${eventUuid}/mini-statement/`
    }
  }
};

export const STRINGS = {
  USER_KEY: 'user',
  TOKEN_KEY: 'token'
};

export const SECRET_KEY = config.secretKey;

export const swrOptions = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false
};

export const getUrl = (
  url: string,
  params: Record<string, any> = {}
): string => {
  let urlString = url;
  if (!/^https?:\/\//.test(urlString)) {
    // if it does not start with http/https, add the base URL
    urlString = `${BASE_URL}/${urlString}`;
  }

  if (params && !_.isEmpty(params)) {
    const encodedParams = btoa(JSON.stringify(params));
    urlString += `?${new URLSearchParams({
      params: encodedParams
    }).toString()}`;
  }
  return urlString;
};
