import {
  Action,
  EventState,
  EventWorkspaceTypes,
  IEventLiveImage
} from '../../shared/interface';
import actionTypes from '../action-types';
import { Reducer } from 'redux';
import {
  SET_ACTIVE_EVENT,
  SET_ACTIVE_EVENT_WORKSPACES,
  SET_EVENT_LIVE_IMAGES,
  SET_EVENT_NEW_IMAGES,
  SET_EVENT_NEW_VIDEOS,
  SET_EVENT_STREAM_DETAILS,
  SET_EVENT_VIDEOS,
  UPDATE_EVENT_NEW_IMAGES,
  UPDATE_EVENT_NEW_VIDEOS
} from '../actions/eventActions';

export const initialPropertiesState: EventState = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  activeEvent: {
    event: null,
    stream: null,
    openWorkspace: null,
    images: {
      newImages: {
        count: 0,
        uploaded: 0,
        items: []
      },
      liveImages: {
        count: 0,
        next: null,
        previous: null,
        results: []
      }
    },
    videos: {
      newVideos: {
        count: 0,
        uploaded: 0,
        items: []
      },
      liveVideos: {
        count: 0,
        next: null,
        previous: null,
        results: []
      }
    }
  }
};

// eslint-disable-next-line complexity
const eventReducer: Reducer<EventState, Action> = (
  state = initialPropertiesState,
  action: Action
) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        next: payload.next || null,
        count: payload.count as number,
        previous: payload.previous || null,
        results: payload.previous
          ? [...state.results, ...payload.results]
          : [...payload.results]
      };
    case SET_ACTIVE_EVENT:
      return {
        ...state,
        activeEvent: {
          ...(payload.event?.uuid === state.activeEvent.event?.uuid
            ? state.activeEvent
            : initialPropertiesState.activeEvent),
          event: payload.event
        }
      };
    case SET_ACTIVE_EVENT_WORKSPACES:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return handleUpdateActiveEventWorkspaces(state, payload);
    case SET_EVENT_LIVE_IMAGES:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return handleImages(state, payload);
    case SET_EVENT_VIDEOS:
      const {
        next,
        previous,
        results: videos
      } = state.activeEvent.videos.liveVideos;

      return {
        ...state,
        activeEvent: {
          ...state.activeEvent,
          videos: {
            ...state.activeEvent.videos,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            liveVideos: handleEventContent(next, previous, videos, payload)
          }
        }
      };
    case SET_EVENT_NEW_IMAGES:
      return {
        ...state,
        activeEvent: {
          ...state.activeEvent,
          images: {
            ...state.activeEvent.images,
            newImages: {
              items: payload.items,
              count: payload.items.length,
              uploaded: 0
            }
          }
        }
      };
    case UPDATE_EVENT_NEW_IMAGES:
      return {
        ...state,
        activeEvent: {
          ...state.activeEvent,
          images: {
            ...state.activeEvent.images,
            newImages: {
              uploaded:
                payload.uploaded || state.activeEvent.images.newImages.uploaded,
              items: payload.images || state.activeEvent.images.newImages.items,
              count: payload.count || state.activeEvent.images.newImages.count
            }
          }
        }
      };
    case SET_EVENT_NEW_VIDEOS:
      return {
        ...state,
        activeEvent: {
          ...state.activeEvent,
          videos: {
            ...state.activeEvent.videos,
            newVideos: {
              items: payload.items,
              count: payload.items.length,
              uploaded: 0
            }
          }
        }
      };
    case UPDATE_EVENT_NEW_VIDEOS:
      return {
        ...state,
        activeEvent: {
          ...state.activeEvent,
          videos: {
            ...state.activeEvent.videos,
            newVideos: {
              uploaded:
                payload.uploaded || state.activeEvent.videos.newVideos.uploaded,
              items: payload.images || state.activeEvent.videos.newVideos.items,
              count: payload.count || state.activeEvent.videos.newVideos.count
            }
          }
        }
      };
    case SET_EVENT_STREAM_DETAILS:
      return {
        ...state,
        activeEvent: {
          ...state.activeEvent,
          event: {
            ...state.activeEvent.event,
            ...(payload.data ?? {})
          }
        }
      };
    default:
      return state;
  }
};

function handleImages(
  state: EventState,
  payload: {
    count: number;
    results: IEventLiveImage[];
    next: string | null;
    previous: string | null;
  }
) {
  const {
    next: nxt,
    previous: prev,
    results: images
  } = state.activeEvent.images.liveImages;

  return {
    ...state,
    activeEvent: {
      ...state.activeEvent,
      images: {
        ...state.activeEvent.images,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        liveImages: handleEventContent(nxt, prev, images, payload)
      }
    }
  };
}

function handleUpdateActiveEventWorkspaces(
  state: EventState,
  openWorkspace: EventWorkspaceTypes
): EventState {
  return {
    ...state,
    activeEvent: {
      ...state.activeEvent,
      openWorkspace
    }
  };
}

function handleEventContent(
  next: string | null,
  previous: string | null,
  content: any[],
  payload: {
    count: number;
    results: IEventLiveImage[];
    next: string | null;
    previous: string | null;
  }
) {
  const allContent = {
    ...payload,
    results: [...payload.results, ...content]
  };

  if (payload.count === 0) return payload;
  const newContent =
    content.length && !payload.results.length ? allContent : payload;

  return next || previous ? allContent : newContent;
}

export default eventReducer;
