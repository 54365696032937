import React, { useMemo, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box
} from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import httpService from '../../../../shared/services/http.service';
import { API_CONFIG } from '../../../../shared/constants/constants';

interface LivestreamSubscriptionModalProps {
  open: boolean;
  onClose: () => void;
  eventUuid: string;
}

const LivestreamSubscriptionModal: React.FC<
  LivestreamSubscriptionModalProps
> = ({ open, onClose, eventUuid }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [hours, setHours] = useState('12');
  const [loading, setLoading] = useState(false);

  const totalAmount = useMemo(() => {
    const numHours = parseInt(hours, 10) || 0;
    return numHours * 5;
  }, [hours]);

  const handleSubmit = async () => {
    if (parseInt(hours, 10) < 5) return;
    setLoading(true);
    try {
      const response = await httpService.post(
        API_CONFIG.PATH.EVENTS.LIVESTREAM_SUBSCRIPTION,
        {
          event_uuid: eventUuid,
          phone_number: phoneNumber,
          number_of_hours: hours,
          payment_method: 'mobilemoneyuganda'
        }
      )();

      if (response.redirect_url) {
        window.location.href = response.redirect_url;
      }
    } catch (error) {
      console.error('Subscription error:', error);
    }
    setLoading(false);
  };

  const isHoursValid = parseInt(hours, 10) >= 5;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Subscribe to Livestream</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <PhoneInput
            country={'ug'}
            value={phoneNumber}
            onChange={phone => setPhoneNumber(phone)}
            inputStyle={{ width: '100%' }}
            containerStyle={{ marginBottom: '32px' }}
          />
          <TextField
            fullWidth
            type="number"
            label="Number of Live stream Hours"
            value={hours}
            onChange={e => setHours(e.target.value)}
            InputProps={{
              inputProps: { min: 5 }
            }}
            error={!isHoursValid}
            helperText={!isHoursValid ? 'Minimum 5 hours required' : undefined}
          />
          <Box
            sx={{
              mt: 3,
              p: 2,
              borderRadius: 1,
              bgcolor: '#f5f5f5',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Box sx={{ fontSize: '1.1rem', fontWeight: 500, mb: 1 }}>
              Total Amount
            </Box>
            <Box
              sx={{
                fontSize: '1.8rem',
                fontWeight: 700,
                color: 'primary.main'
              }}
            >
              ${totalAmount}
            </Box>
            <Box sx={{ mt: 0.5, fontSize: '0.9rem', color: 'text.secondary' }}>
              (${5}/hour)
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading || !phoneNumber || !hours || !isHoursValid}
        >
          {loading ? 'Processing...' : 'Subscribe'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LivestreamSubscriptionModal;
