import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import {
  Menu as MenuClosedIcon,
  MenuOpen as MenuOpenIcon
} from '@material-ui/icons';
import {
  Avatar,
  Box,
  CssBaseline,
  Dialog,
  DialogContent,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  styled,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import { LanguageOutlined } from '@mui/icons-material';
import AppSideMenu from '../../shared/components/side-menu';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { useDispatch, useSelector } from 'react-redux';
import { LOGOUT_SUCCESS } from '../../store/actions/authActions';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { State } from '../../shared/interface';
import AuthStorage from '../../shared/utils/authStorage';
import { PATHS } from '../../router/routes';
import CreateEventBtn from '../../shared/components/create-event-btn';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from '../../shared/constants/constants';
import CompletionSubscription from '../../pages/subscriptions/completed';
import CheckoutForm from '../../pages/subscriptions/checkout-form';
import { SET_ACTIVE_EVENT } from '../../store/actions/eventActions';
import WalletBalance from '../../shared/components/wallet-balance';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
// eslint-disable-next-line prettier/prettier,max-len
const uuidRegex =
  /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}/;

export const drawerWidth = 240;

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  }),
  ...(!open && {
    marginLeft: `calc(${theme.spacing(8)} + 1px)`,
    width: `calc(100% - ${`calc(${theme.spacing(8)} + 1px)`})`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

// 'Profile', 'Account', 'Dashboard',
const settings = ['Logout'];

interface LayoutProps {
  children: ReactNode;
}

function Layout({ children }: LayoutProps) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state: State) => state.auth.user);
  const isDesktop = useMediaQuery(theme.breakpoints.down('md'));
  const [isDrawerOpen, setIsDrawerOpen] = useState(isDesktop);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const isAuthenticated = useSelector(
    (state: State) => state.auth.isAuthenticated
  );
  const { clientSecret, subscriptionComplete } = useSelector(
    (state: State) => state.auth.user
  );

  const eventName = useSelector(
    (state: State) => state.event.activeEvent?.event?.name as string
  );

  const toggleDrawer = useCallback(() => {
    setIsDrawerOpen(!isDrawerOpen);
  }, [isDrawerOpen]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (setting?: string) => {
    if (setting) {
      if (setting.toLowerCase() === 'logout') {
        dispatch({ type: LOGOUT_SUCCESS, payload: {} });
      }
    }
    setAnchorElUser(null);
  };

  const handleApprovedCompanyRouting = (company: any, currentPath: string) => {
    // Add these allowed paths
    const allowedPaths = [
      'free-trial',
      'subscriptions',
      'dashboard',
      'onboarding',
      'auth',
      'settings'
    ];

    // Don't redirect if current path is in allowed paths
    if (allowedPaths.some(path => currentPath.includes(path))) {
      return null;
    }

    // Determine where to redirect
    if (!company.has_active_subscription) {
      if (!company.has_used_trial) {
        return '/free-trial';
      }
      return '/subscriptions';
    }

    return '/dashboard';
  };

  useEffect(() => {
    const token = AuthStorage.getAccessToken();
    if (!isAuthenticated || !user || !token) {
      if (!location.pathname.includes('/auth')) {
        navigate('/auth', {
          state: { from: location.pathname },
          replace: true
        });
      }
      return;
    }

    if (!settings.find(item => item === user.email)) {
      settings.unshift(user.email);
    }

    if (!settings.find(item => item === user.company.name)) {
      settings.unshift(user.company.name ?? 'null');
    }

    const currentPath = location.pathname;

    if (!user.hasCompany) {
      if (!currentPath.includes(PATHS.onboarding)) {
        navigate(PATHS.onboarding, { replace: true });
      }
      return;
    }

    if (user.company.status === 'PENDING') {
      if (!currentPath.includes(PATHS.onboarding)) {
        navigate('/onboarding', { replace: true });
      }
      return;
    }

    if (user.company.status === 'APPROVED') {
      const redirectPath = handleApprovedCompanyRouting(
        user.company,
        currentPath
      );
      if (redirectPath) {
        navigate(redirectPath, { replace: true });
      }
    }
  }, [isAuthenticated, navigate, user, location.pathname]);

  useEffect(() => {
    const { pathname } = location;
    if (
      !(
        pathname.includes('/event/') &&
        uuidRegex.test(pathname.split('/event/')[1])
      )
    ) {
      dispatch({
        type: SET_ACTIVE_EVENT,
        payload: { event: null }
      });
    }
  }, [location, dispatch]);

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <CssBaseline />
      <AppBar position="fixed" color="secondary" open={isDrawerOpen}>
        <Toolbar>
          <IconButton
            edge="start"
            color="primary"
            aria-label="menu"
            onClick={toggleDrawer}
          >
            {isDrawerOpen ? <MenuOpenIcon /> : <MenuClosedIcon />}
          </IconButton>
          <Typography variant="h6" className="ml-2 capitalize" color="primary">
            {eventName ||
              window.location.pathname.split('/').at(-1) ||
              'Dashboard'}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          {user?.hasCompany && <WalletBalance />}
          <CreateEventBtn />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton
              size="large"
              aria-label="switch language"
              color="primary"
            >
              <LanguageOutlined />
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={user.company.name}
                  src={user.company.logo || undefined}
                >
                  {(user.company.name ?? '').length === 0
                    ? user.username.charAt(0)
                    : user.company.name.charAt(0)}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              className="mt-8"
              id="menu-app-bar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorElUser)}
              onClose={() => handleCloseUserMenu()}
            >
              {settings.map(setting => (
                <MenuItem
                  key={setting}
                  onClick={() => handleCloseUserMenu(setting)}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <AppSideMenu isDrawerOpen={isDrawerOpen} />
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 2, mt: 8, position: 'relative' }}
      >
        <Box
          sx={{
            flex: 1,
            minHeight: '89vh',
            width: location.pathname.includes('event')
              ? `calc(100% - ${`calc(${theme.spacing(8)} + 1px)`})`
              : 'initial',
            mr: location.pathname.includes('event')
              ? `calc(${theme.spacing(8)} + 1px)`
              : 0
          }}
        >
          <Paper
            sx={{
              minHeight: 'inherit',
              bgcolor: location.pathname.includes('onboarding')
                ? '#f5f5f5'
                : 'background.paper',
              '& .MuiStepLabel-root': {
                '& .MuiStepIcon-text': {
                  fill: '#fff' // Number color inside the circle
                },
                '& .MuiStepIcon-root': {
                  color: 'rgba(0, 0, 0, 0.38)', // Default circle color
                  '&.Mui-active': {
                    color: theme.palette.primary.main
                  },
                  '&.Mui-completed': {
                    color: theme.palette.primary.main
                  }
                }
              },
              '& .MuiStepConnector-root': {
                '& .MuiStepConnector-line': {
                  borderColor: 'rgba(0, 0, 0, 0.23)'
                }
              },
              '& .MuiStepLabel-label': {
                color: 'rgba(0, 0, 0, 0.6)',
                '&.Mui-active': {
                  color: 'rgba(0, 0, 0, 0.87)'
                }
              }
            }}
            className="shadow-md p-2"
          >
            {!!clientSecret ? (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <Dialog open={true}>
                  <DialogContent>
                    {subscriptionComplete ? (
                      <CompletionSubscription stripePromise={stripePromise} />
                    ) : (
                      <CheckoutForm />
                    )}
                  </DialogContent>
                </Dialog>
              </Elements>
            ) : null}
            {children}
          </Paper>
        </Box>
      </Box>
    </Box>
  );
}

export default Layout;
