import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  FormLabel,
  FormHelperText
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDispatch } from 'react-redux';
import httpService from '../../../shared/services/http.service';
import { API_CONFIG } from '../../../shared/constants/constants';
import { fetchCompanyDetails } from '../../../store/actions/authActions';
import actionTypes from '../../../store/action-types';
import { NotificationType } from '../../../shared/interface';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ACCEPTED_FORMATS = [
  'application/pdf',
  'image/jpeg',
  'image/png',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];

interface Props {
  onNext: () => void;
  onBack?: () => void;
}

interface FormValues {
  company_document_one: File | null;
  company_document_two: File | null;
  company_document_three: File | null;
}

const validationSchema = Yup.object().shape({
  company_document_one: Yup.mixed()
    .nullable()
    .test('fileSize', 'File size is too large (max 5MB)', (value: any) => {
      if (!value) return true;
      return value.size <= MAX_FILE_SIZE;
    })
    .test('fileFormat', 'Unsupported file format', (value: any) => {
      if (!value) return true;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return Boolean(value.type && ACCEPTED_FORMATS.includes(value.type));
    }),
  company_document_two: Yup.mixed()
    .nullable()
    .test('fileSize', 'File size is too large (max 5MB)', (value: any) => {
      if (!value) return true;
      return value.size <= MAX_FILE_SIZE;
    })
    .test('fileFormat', 'Unsupported file format', (value: any) => {
      if (!value) return true;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return Boolean(value.type && ACCEPTED_FORMATS.includes(value.type));
    }),
  company_document_three: Yup.mixed()
    .nullable()
    .test('fileSize', 'File size is too large (max 5MB)', (value: any) => {
      if (!value) return true;
      return value.size <= MAX_FILE_SIZE;
    })
    .test('fileFormat', 'Unsupported file format', (value: any) => {
      if (!value) return true;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return Boolean(value.type && ACCEPTED_FORMATS.includes(value.type));
    })
});

const DocumentsStep: React.FC<Props> = ({ onNext, onBack }) => {
  const dispatch = useDispatch();
  const [fileNames, setFileNames] = useState<{ [key: string]: string }>({});

  const formik = useFormik<FormValues>({
    initialValues: {
      company_document_one: null,
      company_document_two: null,
      company_document_three: null
    },
    validationSchema,
    onSubmit: async values => {
      try {
        const formData = new FormData();
        if (values.company_document_one) {
          formData.append('company_document_one', values.company_document_one);
        }
        if (values.company_document_two) {
          formData.append('company_document_two', values.company_document_two);
        }
        if (values.company_document_three) {
          formData.append(
            'company_document_three',
            values.company_document_three
          );
        }

        await httpService.patch(
          `${API_CONFIG.PATH.COMPANY.UPDATE}?status=submitted`,
          formData,
          {},
          { contentType: 'multipart/form-data' }
        )(dispatch, true);

        await fetchCompanyDetails(dispatch);

        dispatch({
          type: actionTypes.SET_NOTIFICATION,
          payload: {
            message: 'Documents uploaded successfully',
            type: NotificationType.SUCCESS
          }
        });

        onNext();
      } catch (error: any) {
        dispatch({
          type: actionTypes.SET_NOTIFICATION,
          payload: {
            message:
              error.response?.data?.message || 'Failed to upload documents',
            type: NotificationType.ERROR
          }
        });
      }
    }
  });

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: keyof FormValues
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      formik.setFieldValue(fieldName, file);
      setFileNames(prev => ({ ...prev, [fieldName]: file.name }));
    }
  };

  const renderUploadBox = (
    fieldName: keyof FormValues,
    label: string,
    description: string
  ) => (
    <Grid item xs={12}>
      <FormLabel>{label}</FormLabel>
      <Box
        sx={{
          mt: 2,
          p: 3,
          border: '2px dashed',
          borderColor: 'divider',
          borderRadius: 1,
          bgcolor: 'background.default',
          cursor: 'pointer',
          '&:hover': {
            borderColor: 'primary.main',
            bgcolor: 'action.hover'
          }
        }}
        onClick={() => document.getElementById(fieldName)?.click()}
      >
        <input
          id={fieldName}
          type="file"
          hidden
          accept={ACCEPTED_FORMATS.join(',')}
          onChange={e => handleFileChange(e, fieldName)}
        />
        <Box sx={{ textAlign: 'center' }}>
          {fileNames[fieldName] ? (
            <Typography>{fileNames[fieldName]}</Typography>
          ) : (
            <>
              <CloudUploadIcon
                sx={{ fontSize: 48, color: 'text.secondary', mb: 1 }}
              />
              <Typography>{description}</Typography>
            </>
          )}
        </Box>
      </Box>
      {formik.touched[fieldName] && formik.errors[fieldName] && (
        <FormHelperText error>
          {formik.errors[fieldName] as string}
        </FormHelperText>
      )}
    </Grid>
  );

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Typography variant="h5" gutterBottom>
        Upload Documents
      </Typography>
      <Typography color="text.secondary" sx={{ mb: 4 }}>
        Please provide your business documents (Optional)
      </Typography>

      <Grid container spacing={3}>
        {renderUploadBox(
          'company_document_one',
          'Registration Company Certificate',
          'Click to upload company registration certificate'
        )}
        {renderUploadBox(
          'company_document_two',
          'Memorandum and Articles of Association',
          'Click to upload memorandum'
        )}
        {renderUploadBox(
          'company_document_three',
          'National ID',
          'Click to upload national ID'
        )}
      </Grid>

      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={onBack}>Back</Button>
        <Button variant="contained" type="submit">
          Complete Registration
        </Button>
      </Box>
    </Box>
  );
};

export default DocumentsStep;
