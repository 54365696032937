import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Container, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import { API_CONFIG } from '../../shared/constants/constants';
import { useDispatch } from 'react-redux';
import { NotificationType } from '../../shared/interface';
import actionTypes from '../../store/action-types';
import httpService from '../../shared/services/http.service';
import {
  fetchCompanyDetails as fetchCompanyAction,
  fetchCompanyDetails
} from '../../store/actions/authActions';

const FreeTrialPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isNavigating, setIsNavigating] = useState(false);
  const [showCelebration, setShowCelebration] = useState(false);
  const { width, height } = useWindowSize();
  const [loading, setLoading] = useState(false);

  const handleUpgrade = useCallback(() => {
    if (isNavigating) return;
    setIsNavigating(true);
    navigate('/subscriptions', { replace: true });
  }, [navigate, isNavigating]);

  useEffect(() => {
    return () => setIsNavigating(false);
  }, []);
  useCallback(async () => {
    try {
      await fetchCompanyAction();
    } catch (error) {
      console.error('Error fetching company details:', error);
    }
  }, []);
  const handleFreeTrial = async () => {
    setLoading(true);
    try {
      const response = await httpService.post(
        API_CONFIG.PATH.PACKAGES.FREE_TRIAL,
        {},
        {},
        { contentType: 'application/json' }
      )(dispatch, true);

      if (response) {
        setShowCelebration(true);
        await fetchCompanyDetails(dispatch);

        dispatch({
          type: actionTypes.SET_NOTIFICATION,
          payload: {
            message: 'Free trial activated successfully!',
            type: NotificationType.SUCCESS
          }
        });

        setTimeout(() => {
          setShowCelebration(false);
          navigate('/dashboard', { replace: true });
        }, 6000);
      }
    } catch (error) {
      console.error('Error activating free trial:', error);
      dispatch({
        type: actionTypes.SET_NOTIFICATION,
        payload: {
          message: 'Failed to activate free trial',
          type: NotificationType.ERROR
        }
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      {showCelebration && (
        <>
          <Confetti
            width={width}
            height={height}
            numberOfPieces={200}
            recycle={false}
          />
          <Box
            sx={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1000,
              textAlign: 'center',
              animation: 'fadeIn 0.5s ease-in'
            }}
          />
        </>
      )}
      <Box sx={{ mt: 8, textAlign: 'center' }}>
        <Paper
          elevation={3}
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 3
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Welcome to Pixsar Media Studio!
          </Typography>
          <Typography variant="body1" color="text.secondary" paragraph>
            Start with a 14-day free trial or upgrade to a premium plan right
            away.
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleFreeTrial}
              disabled={loading}
            >
              {loading ? 'Activating...' : 'Start Free Trial'}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={handleUpgrade}
              disabled={loading}
            >
              Upgrade Plan
            </Button>
          </Box>
        </Paper>
      </Box>
      <style>
        {`
          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }
          @keyframes scaleUp {
            from { transform: scale(0.8); opacity: 0; }
            to { transform: scale(1); opacity: 1; }
          }
          @keyframes slideUp {
            from { transform: translateY(20px); opacity: 0; }
            to { transform: translateY(0); opacity: 1; }
          }
        `}
      </style>
    </Container>
  );
};

export default FreeTrialPage;
