import React, { ReactNode, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {
  Box,
  Container,
  Grid,
  IconButton,
  styled,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AppLogo from '../../assets/images/pixsar-logo-purple.svg';
import AuthIllustration from '../../assets/images/illustrations/auth-pages-illustration.svg';
import { HelpOutline, LanguageOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

interface StyledImageProps {
  width?: number | string;
  height?: number | string;
}

export const StyledImage = styled('img')<StyledImageProps>`
  height: ${props => (props.height ? `${props.height}` : '70px')};
  width: ${props => (props.width ? `${props.width}` : '100px')};
  object-fit: contain;
`;

interface AuthLayoutProps {
  children: ReactNode;
}

function AuthLayout({ children }: AuthLayoutProps) {
  const theme = useTheme();
  const location = useLocation(); // Use React Router's useLocation
  const { search } = location;
  const searchParams = new URLSearchParams(search);
  const from = searchParams.get('from');
  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );
  const user = useSelector((state: any) => state.auth.user);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (isAuthenticated && user) {
      const currentPath = location.pathname;

      // Already authenticated users shouldn't be in auth pages
      if (currentPath.includes('/auth')) {
        if (user.hasCompany) {
          if (user.company.status === 'APPROVED') {
            if (user.company.has_active_subscription) {
              navigate('/dashboard', { replace: true });
            } else {
              // Check if user hasn't used free trial yet
              if (!user.company.has_used_trial) {
                navigate('/free-trial', { replace: true });
              } else {
                navigate('/subscriptions', { replace: true });
              }
            }
          } else {
            navigate('/onboarding', { replace: true });
          }
        } else {
          navigate('/onboarding', { replace: true });
        }
      }
    }
  }, [isAuthenticated, user, location.pathname, navigate]);

  return (
    <Box sx={{ width: '100vw', minHeight: '100vh' }}>
      <AppBar position="static" color="secondary" className="shadow-md">
        <Toolbar>
          <StyledImage src={AppLogo} />
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton
              size="large"
              aria-label="switch language"
              color="primary"
            >
              <LanguageOutlined />
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="pixsar-faqs-page"
              color="primary"
            >
              <HelpOutline />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        sx={{ height: '92vh' }}
        className="flex flex-col align-c justify-center"
      >
        <Container className="mt-md-10 px-15" maxWidth="xl">
          <Grid container spacing={3}>
            {!isMobile && (
              <Grid
                item
                md={6}
                className="pt-0 flex flex-col justify-center align-middle"
              >
                <StyledImage
                  src={AuthIllustration}
                  height="70vh"
                  width="100%"
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={isMobile ? 12 : 6}
              sx={{ paddingTop: isMobile ? 0 : '4px' }}
              className="pt-0 flex flex-col justify-center align-middle"
            >
              <Container maxWidth={false}>
                <Box className="min-h-screen flex items-center justify-center">
                  {children}
                </Box>
              </Container>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default AuthLayout;
