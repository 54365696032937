import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Paper,
  Table,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useSelector } from 'react-redux';
import { State } from '../../../../shared/interface';
import httpService from '../../../../shared/services/http.service';
import { format } from 'date-fns';
import { API_CONFIG } from '../../../../shared/constants/constants';

interface MiniStatement {
  id: number;
  email: string;
  amount: string;
  currency: string;
  transaction_ref: string;
  transaction_type: string;
  created: string;
}

interface MiniStatementResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: MiniStatement[];
}

const MiniStatements: React.FC = () => {
  const [statements, setStatements] = useState<MiniStatement[]>([]);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { event: eventDetails } = useSelector(
    (state: State) => state.event.activeEvent
  );

  const fetchMiniStatements = useCallback(
    async (pageNumber: number) => {
      try {
        if (!eventDetails?.uuid) {
          throw new Error('Event UUID is required');
        }
        setLoading(true);
        const response: MiniStatementResponse = await httpService.get(
          `${API_CONFIG.PATH.EVENTS.GET_MINI_STATEMENTS(
            eventDetails.uuid
          )}?page=${pageNumber + 1}&page_size=${rowsPerPage}`
        )();
        setStatements(response.results);
        setTotalCount(response.count);
      } catch (err) {
        console.error('Error fetching mini statements:', err);
      } finally {
        setLoading(false);
      }
    },
    [eventDetails?.uuid, rowsPerPage]
  );

  const handleDownloadPDF = async () => {
    try {
      if (!eventDetails?.uuid) {
        throw new Error('Event UUID is required');
      }
      setDownloading(true);
      const response = await httpService.get(
        API_CONFIG.PATH.EVENTS.DOWNLOAD_MINI_STATEMENTS_PDF(eventDetails.uuid),
        {},
        { responseType: 'blob' }
      )();

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${eventDetails.name}_mini_statement.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error('Error downloading PDF:', err);
    }
  };

  useEffect(() => {
    if (eventDetails?.uuid) {
      fetchMiniStatements(page);
    }
  }, [eventDetails?.uuid, page, rowsPerPage, fetchMiniStatements]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseError = () => {
    setError(null);
  };

  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={
            downloading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <DownloadIcon />
            )
          }
          onClick={handleDownloadPDF}
          disabled={downloading || loading || totalCount === 0}
        >
          {downloading ? 'Downloading...' : 'Download PDF'}
        </Button>
      </Box>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Transaction Ref</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={5} align="center" sx={{ py: 3 }}>
                    <CircularProgress size={24} />
                  </TableCell>
                </TableRow>
              ) : statements.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={5} align="center" sx={{ py: 3 }}>
                    No mini statements available at the moment
                  </TableCell>
                </TableRow>
              ) : (
                statements.map(row => (
                  <TableRow key={row.id}>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>
                      {row.amount} {row.currency}
                    </TableCell>
                    <TableCell>{row.transaction_ref}</TableCell>
                    <TableCell>{row.transaction_type}</TableCell>
                    <TableCell>
                      {format(new Date(row.created), 'dd/MM/yyyy HH:mm')}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleCloseError}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MiniStatements;
