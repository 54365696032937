import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import ReactPlayer from 'react-player/lazy';
import httpService from '../../../../shared/services/http.service';
import { API_CONFIG, swrOptions } from '../../../../shared/constants/constants';
import useSWR from 'swr';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationType, State } from '../../../../shared/interface';
import StreamSetupLoader from './loader';
import {
  fetchStream,
  SET_EVENT_STREAM_DETAILS
} from '../../../../store/actions/eventActions';
import actionTypes from '../../../../store/action-types';
import { AxiosError } from 'axios';

const StreamComponent = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    uuid: eventUuid,
    stream_link,
    rtmp_link,
    status,
    name: eventName
  } = useSelector((state: State) => state.event.activeEvent?.event) ?? {};
  const { isLoading } = useSWR(
    eventUuid ? API_CONFIG.PATH.EVENTS.GET_EVENT_STREAM(eventUuid) : null,
    () => fetchStream(eventUuid || ''),
    { ...swrOptions, shouldRetryOnError: false, revalidateOnMount: true }
  );
  const buttonLabel = status === 'STARTED' ? 'Stop Stream' : 'Start Stream';

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    dispatch({
      type: actionTypes.SET_NOTIFICATION,
      payload: {
        message: `Copied to clipboard: ${text}`,
        type: NotificationType.SUCCESS
      }
    });
  };

  const handleStreamActions = async () => {
    setLoading(true);
    dispatch({
      type: actionTypes.API_REQUEST_INIT,
      payload: { loading: true }
    });
    try {
      const response = await httpService.post(
        API_CONFIG.PATH.EVENTS.EVENT_STREAM(eventUuid as string),
        {
          action: status === 'STARTED' ? 'stop' : 'start'
        }
      )(dispatch);

      if (response.event) {
        dispatch({
          type: actionTypes.API_REQUEST_SUCCESS
        });

        dispatch({
          type: SET_EVENT_STREAM_DETAILS,
          payload: {
            data: {
              rtmp_link: response.event.rtmp,
              stream_link: response.event.stream_link,
              status: response.event.status
            }
          }
        });

        dispatch({
          type: actionTypes.SET_NOTIFICATION,
          payload: {
            message: response.message,
            type: NotificationType.SUCCESS
          }
        });
      }
    } catch (err) {
      const error = err as AxiosError<{ error: string }>;
      dispatch({
        type: actionTypes.SET_NOTIFICATION,
        payload: {
          message: error.response?.data?.error || 'Failed to manage stream',
          type: NotificationType.ERROR
        }
      });
    }
    setLoading(false);
  };

  if (isLoading) {
    return <StreamSetupLoader />;
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item>
          <Typography variant="h5" className="capitalize">
            {eventName}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color={status === 'STARTED' ? 'error' : 'primary'}
            className="capitalize"
            onClick={() => handleStreamActions()}
            disabled={loading}
            sx={{
              backgroundColor: status === 'STARTED' ? 'red' : 'primary.main',
              animation: status === 'STARTED' ? 'blink 2s infinite' : 'none',
              '@keyframes blink': {
                '0%': { opacity: 1 },
                '50%': { opacity: 0.6 },
                '100%': { opacity: 1 }
              }
            }}
          >
            {loading ? 'Processing...' : buttonLabel}
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={12} md={8}>
          <Paper
            sx={{ padding: 2, marginBottom: 2, minHeight: '40vh' }}
            className="flex flex-column justify-center align-middle text-gray-500"
          >
            {status === 'STARTED' && !!stream_link ? (
              <ReactPlayer
                url={stream_link}
                fallback={
                  <Box
                    sx={{
                      height: '40vh',
                      width: '100%',
                      backgroundColor: 'black'
                    }}
                  />
                }
                controls
                width="100%"
                height="45vh"
              />
            ) : (
              <div className="my-auto">Live Stream Offline</div>
            )}
          </Paper>
          {status === 'STARTED' ? (
            <Paper sx={{ padding: 2 }}>
              <Typography variant="h6">Comments</Typography>
              <Box sx={{ maxHeight: 400, overflowY: 'auto', marginTop: 1 }}>
                <Typography variant="body2">User1: Great stream!</Typography>
                <Typography variant="body2">
                  User2: Loving the content!
                </Typography>
              </Box>
            </Paper>
          ) : null}
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ padding: 2, marginBottom: 2 }}>
            <Typography variant="h6">RTMP Links</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
              <TextField
                placeholder="RTMP URL will appear here after starting the stream"
                variant="outlined"
                fullWidth
                value={rtmp_link || ''}
                InputProps={{
                  readOnly: true
                }}
              />
              <IconButton
                onClick={() => handleCopy(rtmp_link || '')}
                edge="end"
              >
                <ContentCopy />
              </IconButton>
            </Box>
          </Paper>
          {rtmp_link ? (
            <Paper sx={{ padding: 2 }}>
              <Typography variant="h6">Setup OBS</Typography>
              <List
                component="ol"
                sx={{ listStyleType: 'decimal', paddingLeft: 4 }}
              >
                <ListItem component="li" sx={{ display: 'list-item' }}>
                  <ListItemText primary="Open OBS and go to Settings." />
                </ListItem>
                <ListItem component="li" sx={{ display: 'list-item' }}>
                  <ListItemText primary="Navigate to the “Stream” tab." />
                </ListItem>
                <ListItem component="li" sx={{ display: 'list-item' }}>
                  <ListItemText primary="Select “Custom...” in the “Service” dropdown." />
                </ListItem>
                <ListItem component="li" sx={{ display: 'list-item' }}>
                  <ListItemText primary="Enter the RTMP link in the “Server” field." />
                </ListItem>
                <ListItem component="li" sx={{ display: 'list-item' }}>
                  <ListItemText primary="Enter your stream key." />
                </ListItem>
                <ListItem component="li" sx={{ display: 'list-item' }}>
                  <ListItemText primary="Click “Apply” and then “OK”." />
                </ListItem>
              </List>
            </Paper>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export default StreamComponent;
