import React from 'react';
import { Box, Button, Typography, FormLabel, Grid } from '@mui/material';
import CountrySelect from '../../../shared/components/country-select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import httpService from '../../../shared/services/http.service';
import { API_CONFIG } from '../../../shared/constants/constants';
import { fetchCompanyDetails } from '../../../store/actions/authActions';
import actionTypes from '../../../store/action-types';
import { NotificationType } from '../../../shared/interface';

interface Props {
  onNext: () => void;
}

const validationSchema = Yup.object().shape({
  country: Yup.string().required('Country is required'),
  phone: Yup.string().required('Phone number is required')
});

const CountryPhoneStep: React.FC<Props> = ({ onNext }) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      country: '',
      phone: ''
    },
    validationSchema,
    onSubmit: async values => {
      try {
        await httpService.patch(API_CONFIG.PATH.COMPANY.UPDATE, {
          country: values.country,
          support_number: values.phone
        })(dispatch, true);

        // Refresh company details in Redux store
        await fetchCompanyDetails(dispatch);

        dispatch({
          type: actionTypes.SET_NOTIFICATION,
          payload: {
            message: 'Company location details updated successfully',
            type: NotificationType.SUCCESS
          }
        });

        onNext();
      } catch (error) {
        dispatch({
          type: actionTypes.SET_NOTIFICATION,
          payload: {
            message: 'Failed to update company location details',
            type: NotificationType.ERROR
          }
        });
      }
    }
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Typography variant="h5" gutterBottom>
        Lets get started
      </Typography>
      <Typography color="text.secondary" sx={{ mb: 4 }}>
        Please tell us where you are located
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormLabel>Country</FormLabel>
          <CountrySelect
            name="country"
            placeholder="Select a country"
            value={formik.values.country}
            handleOptionSelected={value => {
              if (value) {
                formik.setFieldValue('country', value.code);
              }
            }}
          />
          {formik.touched.country && formik.errors.country && (
            <Typography color="error" variant="caption">
              {formik.errors.country}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <FormLabel>Phone Number</FormLabel>
          <PhoneInput
            country={formik.values.country.toLowerCase()}
            value={formik.values.phone}
            onChange={phone => formik.setFieldValue('phone', phone)}
            containerStyle={{ marginTop: 8 }}
            inputStyle={{ width: '100%' }}
          />
          {formik.touched.phone && formik.errors.phone && (
            <Typography color="error" variant="caption">
              {formik.errors.phone}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Button
        variant="contained"
        size="large"
        fullWidth
        type="submit"
        sx={{ mt: 4 }}
      >
        Continue
      </Button>
    </Box>
  );
};

export default CountryPhoneStep;
