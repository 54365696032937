import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  FormLabel,
  Typography,
  Avatar,
  FormHelperText,
  MenuItem,
  Tooltip
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDispatch } from 'react-redux';
import httpService from '../../../shared/services/http.service';
import { API_CONFIG } from '../../../shared/constants/constants';
import { fetchCompanyDetails } from '../../../store/actions/authActions';
import actionTypes from '../../../store/action-types';
import { NotificationType } from '../../../shared/interface';

// Constants
const LOGO_MIN_SIZE = 512;
const LOGO_MAX_SIZE = 1024;
const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB
const ACCEPTED_FORMATS = ['image/jpeg', 'image/png'];
const CURRENCY_TOOLTIP =
  'All future transactions on your account will be in the currency selected here';

// Interfaces
interface Props {
  onNext: () => void;
  onBack?: () => void; // Make onBack optional
}

interface FormValues {
  companyName: string;
  location: string;
  description: string;
  currency: string;
  logo: File | null;
}

interface CurrencyOption {
  label: string;
  value: string;
}

// Currency options
const CURRENCIES: CurrencyOption[] = [
  { label: 'Uganda Shillings', value: 'UGX' }
];

const getDimensionErrorMessage = (
  width: number,
  height: number,
  isMinSize: boolean
): string => {
  const size = isMinSize ? LOGO_MIN_SIZE : LOGO_MAX_SIZE;
  const condition = isMinSize ? 'at least' : 'not exceed';
  return (
    `Image dimensions must be ${condition} ${size}x${size}px. ` +
    `Current size: ${width}x${height}px`
  );
};

const validationSchema = Yup.object().shape({
  companyName: Yup.string().required('Company name is required'),
  location: Yup.string().required('Location is required'),
  description: Yup.string().required('Description is required'),
  currency: Yup.string().required('Currency is required'),
  logo: Yup.mixed()
    .required('Company logo is required')
    .test('fileSize', 'File size is too large', (value: any) => {
      if (!value) return true;
      return value.size <= MAX_FILE_SIZE;
    })
    .test('fileFormat', 'Unsupported file format', value => {
      if (!value) return true;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return value.type && ACCEPTED_FORMATS.includes(value.type);
    })
});

const CompanyDetailsStep: React.FC<Props> = ({ onNext, onBack }) => {
  const dispatch = useDispatch();
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);

  // Cleanup preview URL when component unmounts or when previewUrl changes
  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  const formik = useFormik<FormValues>({
    initialValues: {
      companyName: '',
      location: '',
      description: '',
      currency: 'UGX',
      logo: null
    },
    validationSchema,
    onSubmit: async (values: FormValues) => {
      try {
        const formData = new FormData();
        formData.append('name', values.companyName);
        formData.append('location', values.location);
        formData.append('description', values.description);
        formData.append('currency', values.currency);

        if (values.logo) {
          formData.append('logo', values.logo);
        }

        await httpService.patch(
          API_CONFIG.PATH.COMPANY.UPDATE,
          formData,
          {},
          { contentType: 'multipart/form-data' }
        )(dispatch, true);

        await fetchCompanyDetails(dispatch);

        dispatch({
          type: actionTypes.SET_NOTIFICATION,
          payload: {
            message: 'Company details updated successfully',
            type: NotificationType.SUCCESS
          }
        });

        onNext();
      } catch (error: any) {
        console.error('Error updating company details:', error);

        dispatch({
          type: actionTypes.SET_NOTIFICATION,
          payload: {
            message:
              error.response?.data?.message ||
              'Failed to update company details',
            type: NotificationType.ERROR
          }
        });
      }
    }
  });

  const handleFileValidation = (file: File) => {
    // Create preview URL immediately
    const objectUrl = URL.createObjectURL(file);

    // Clean up previous preview URL if it exists
    if (previewUrl) {
      URL.revokeObjectURL(previewUrl);
    }

    setPreviewUrl(objectUrl);

    // Always set the file value first
    formik.setFieldValue('logo', file);
    formik.setFieldTouched('logo', true);

    // Check file type
    if (!ACCEPTED_FORMATS.includes(file.type)) {
      formik.setFieldError(
        'logo',
        'Unsupported file format. Please use PNG or JPEG format.'
      );
      return;
    }

    // Check file size
    if (file.size > MAX_FILE_SIZE) {
      formik.setFieldError(
        'logo',
        'File size is too large. Maximum size is 2MB.'
      );
      return;
    }

    // Check dimensions
    const img = new Image();
    img.onload = () => {
      setDimensions({ width: img.width, height: img.height });

      if (img.width < LOGO_MIN_SIZE || img.height < LOGO_MIN_SIZE) {
        formik.setFieldError(
          'logo',
          getDimensionErrorMessage(img.width, img.height, true)
        );
      } else if (img.width > LOGO_MAX_SIZE || img.height > LOGO_MAX_SIZE) {
        formik.setFieldError(
          'logo',
          getDimensionErrorMessage(img.width, img.height, false)
        );
      }
    };

    img.src = objectUrl;
  };

  const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [file] = event.target.files || [];
    if (file) {
      handleFileValidation(file);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    const [file] = event.dataTransfer.files || [];
    if (file) {
      handleFileValidation(file);
    }
  };

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Typography variant="h5" gutterBottom>
        Company Details
      </Typography>
      <Typography color="text.secondary" sx={{ mb: 4 }}>
        Tell us about your company
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormLabel>Company Logo</FormLabel>
          <Box
            className="upload-area"
            sx={{
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              p: 3,
              border: '2px dashed',
              borderColor: 'divider',
              borderRadius: 1,
              bgcolor: 'background.default',
              cursor: 'pointer',
              '&:hover': {
                borderColor: 'primary.main',
                bgcolor: 'action.hover'
              }
            }}
            component="div"
            onDrop={handleDrop}
            onDragOver={e => e.preventDefault()}
            onClick={() => document.getElementById('logo-upload')?.click()}
          >
            <input
              id="logo-upload"
              type="file"
              accept="image/png,image/jpeg"
              hidden
              onChange={handleLogoChange}
            />
            {previewUrl ? (
              <Box sx={{ textAlign: 'center' }}>
                <Avatar
                  src={previewUrl}
                  alt="Company Logo"
                  sx={{ width: 150, height: 150, mb: 2 }}
                  variant="rounded"
                />
                {dimensions && (
                  <Typography
                    variant="caption"
                    display="block"
                    color="text.secondary"
                  >
                    Dimensions: {dimensions.width}x{dimensions.height}px
                  </Typography>
                )}
              </Box>
            ) : (
              <>
                <CloudUploadIcon
                  sx={{ fontSize: 48, color: 'text.secondary', mb: 1 }}
                />
                <Typography variant="body1" color="text.secondary">
                  Click to upload company logo
                </Typography>
              </>
            )}
          </Box>
          <FormHelperText>
            Recommended: {LOGO_MIN_SIZE}x{LOGO_MIN_SIZE}px (Max {LOGO_MAX_SIZE}x
            {LOGO_MAX_SIZE}px). PNG or JPEG under 2MB.
          </FormHelperText>
          {formik.touched.logo && formik.errors.logo && (
            <FormHelperText error>
              {formik.errors.logo as string}
            </FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <FormLabel>Company Name</FormLabel>
          <TextField
            fullWidth
            name="companyName"
            value={formik.values.companyName}
            onChange={formik.handleChange}
            error={
              formik.touched.companyName && Boolean(formik.errors.companyName)
            }
            helperText={formik.touched.companyName && formik.errors.companyName}
          />
        </Grid>

        <Grid item xs={12}>
          <FormLabel>Location/Address</FormLabel>
          <TextField
            fullWidth
            name="location"
            value={formik.values.location}
            onChange={formik.handleChange}
            error={formik.touched.location && Boolean(formik.errors.location)}
            helperText={formik.touched.location && formik.errors.location}
          />
        </Grid>

        <Grid item xs={12}>
          <Tooltip title={CURRENCY_TOOLTIP}>
            <FormLabel>Currency</FormLabel>
          </Tooltip>
          <TextField
            select
            fullWidth
            name="currency"
            value={formik.values.currency}
            onChange={formik.handleChange}
            disabled
          >
            {CURRENCIES.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label} ({option.value})
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <FormLabel>Company Description</FormLabel>
          <TextField
            fullWidth
            multiline
            rows={4}
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={onBack}>Back</Button>
        <Button variant="contained" type="submit">
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default CompanyDetailsStep;
