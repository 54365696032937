import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { State } from '../shared/interface';
import React from 'react';

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const user = useSelector((state: State) => state.auth.user);

  if (!user?.hasCompany) {
    return <Navigate to="/onboarding" replace />;
  }

  if (user.company.status === 'PENDING') {
    return <Navigate to="/onboarding" replace />;
  }

  if (!user.company.has_active_subscription) {
    if (!user.company.has_used_trial) {
      return <Navigate to="/free-trial" replace />;
    }
    return <Navigate to="/subscriptions" replace />;
  }

  return children;
};

export default ProtectedRoute;
