import { Environment } from '../shared/interface';

interface Config {
  apiUrl: string;
  environment: Environment;
  stripeKey: string;
  clientId: string;
  secretKey: string;
}

const getEnvironmentConfig = (): Config => {
  const environment =
    (process.env.REACT_APP_ENVIRONMENT as Environment) || 'development';

  const configs: Record<Environment, Config> = {
    development: {
      apiUrl:
        process.env.REACT_APP_BASE_URL ||
        'https://pixsarstaging.azurewebsites.net/api',
      environment: 'development',
      stripeKey:
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
        // eslint-disable-next-line max-len
        'pk_test_51JmL9uCBfqU2ltVXncIUnPc2Ho02e5KZnite0LEckHBvoPvPcLW1j8B8rDrGmB9QiAEn9YrDzRx4ceDtkL8fiLRv00re1vFqiJ',
      clientId:
        process.env.REACT_APP_CLIENT_ID ||
        '284540487362-8rap48pl1upc98ea4q4uhpkiviet5sae.apps.googleusercontent.com',
      secretKey:
        process.env.REACT_APP_SECRET_KEY || '1231lkqwjhegy&$523f%eG45@Ygfr56o'
    },
    staging: {
      apiUrl:
        process.env.REACT_APP_BASE_URL ||
        'https://pixsarstaging.azurewebsites.net/api',
      environment: 'staging',
      stripeKey:
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
        // eslint-disable-next-line max-len
        'pk_test_51JmL9uCBfqU2ltVXncIUnPc2Ho02e5KZnite0LEckHBvoPvPcLW1j8B8rDrGmB9QiAEn9YrDzRx4ceDtkL8fiLRv00re1vFqiJ',
      clientId:
        process.env.REACT_APP_CLIENT_ID ||
        '284540487362-8rap48pl1upc98ea4q4uhpkiviet5sae.apps.googleusercontent.com',
      secretKey:
        process.env.REACT_APP_SECRET_KEY || '1231lkqwjhegy&$523f%eG45@Ygfr56o'
    },
    production: {
      apiUrl: process.env.REACT_APP_BASE_URL || 'https://prod.pixsar.io/api',
      environment: 'production',
      stripeKey:
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
        // eslint-disable-next-line max-len
        'pk_test_51JmL9uCBfqU2ltVXncIUnPc2Ho02e5KZnite0LEckHBvoPvPcLW1j8B8rDrGmB9QiAEn9YrDzRx4ceDtkL8fiLRv00re1vFqiJ',
      clientId:
        process.env.REACT_APP_CLIENT_ID ||
        '284540487362-8rap48pl1upc98ea4q4uhpkiviet5sae.apps.googleusercontent.com',
      secretKey:
        process.env.REACT_APP_SECRET_KEY || '1231lkqwjhegy&$523f%eG45@Ygfr56o'
    }
  };

  return configs[environment];
};

export const config = getEnvironmentConfig();
