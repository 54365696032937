import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  fetchEventsTypes,
  SET_ACTIVE_EVENT
} from '../../../../store/actions/eventActions';
import { API_CONFIG, swrOptions } from '../../../../shared/constants/constants';
import useSWR from 'swr';
import { useDispatch, useSelector } from 'react-redux';
import { IEvent, NotificationType, State } from '../../../../shared/interface';
import { useNavigate } from 'react-router-dom';
import 'react-image-crop/dist/ReactCrop.css';
import httpService from '../../../../shared/services/http.service';
import actionTypes from '../../../../store/action-types';
import { PATHS } from '../../../../router/routes';

export const currencyOptions = [
  { value: 'USD', label: 'United States Dollar' },
  { value: 'EUR', label: 'Euro' },
  { value: 'UGX', label: 'Uganda Shillings' }
];

export interface ICreateEventForm {
  name: string;
  event_date: string;
  event_type_uuid: string;
  is_private: boolean;
  is_premium: boolean;
  price: string;
  currency: string;
}

export const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  event_date: yup.date().required('Date is required'),
  event_type_uuid: yup.string().required('Event Type is required'),
  is_private: yup.boolean(),
  is_premium: yup.boolean(),
  price: yup.string().when('isPremium', {
    is: true,
    then: yup.string().required('Price is required'),
    otherwise: yup.string().notRequired()
  }),
  currency: yup.string().when('isPremium', {
    is: true,
    then: yup.string().required('Currency is required'),
    otherwise: yup.string().notRequired()
  })
});

const CreateEventPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: State) => state.auth.user);

  const { data: eventTypes } = useSWR(
    `${API_CONFIG.PATH.EVENTS.TYPES}?limit=100`,
    () => fetchEventsTypes({ limit: 100 }),
    swrOptions
  );

  const [coverImage, setCoverImage] = useState<
    string | ArrayBuffer | undefined
  >('');
  const [croppedImage, setCroppedImage] = useState<File | null>(null);
  // const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  // const [cropData, setCropData] = useState({
  //   croppedAreaPixels: {},
  //   rotation: 0
  // });
  const [photoUpdating, setPhotoUpdating] = useState(false);
  // const [photoSaving, setPhotoSaving] = useState(false);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileTypes = ['image/jpg', 'image/png', 'image/jpeg'];
    const file = event.target.files?.[0];

    // Check if file is selected and its type is valid
    if (file && fileTypes.includes(file.type)) {
      // Check file size (900 KB = 900 * 1024 bytes)
      if (file.size <= 900 * 1024) {
        const imageDataUrl = await readFile(file);
        setCroppedImage(file as File);
        setCoverImage(imageDataUrl as string);
      } else {
        dispatch({
          type: actionTypes.SET_NOTIFICATION,
          payload: {
            message: 'Error: File size should be less than 900KB',
            type: NotificationType.ERROR
          }
        });
      }
    } else {
      dispatch({
        type: actionTypes.SET_NOTIFICATION,
        payload: {
          message: 'Error: Incorrect file type',
          type: NotificationType.ERROR
        }
      });
    }
  };

  const readFile = (file: File): Promise<any> => {
    setPhotoUpdating(true);
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setPhotoUpdating(false);
        resolve(reader.result);
      });
      reader.readAsDataURL(file);
    });
  };

  const handleFilePickerClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      event_date: '',
      event_type_uuid: eventTypes?.results?.[0]?.uuid || '',
      is_private: false,
      is_premium: false,
      price: '',
      currency: 'UGX'
    },
    validationSchema,
    onSubmit: async (values: ICreateEventForm) => {
      try {
        const createEventFormData = new FormData();

        (Object.keys(values) as (keyof ICreateEventForm)[]).forEach(key => {
          if (values[key] !== undefined && values[key] !== null) {
            createEventFormData.set(key, String(values[key]));
            if (key === 'price' && values.is_premium) {
              createEventFormData.set(key, values.price.replaceAll(',', ''));
            }
          }
        });

        if (croppedImage) {
          createEventFormData.set('image', croppedImage);
        }

        const response: IEvent = await httpService.post(
          API_CONFIG.PATH.EVENTS.CREATE,
          createEventFormData,
          {},
          { contentType: 'multipart/form-data' }
        )(dispatch, true);
        dispatch({
          type: actionTypes.SET_NOTIFICATION,
          payload: {
            message: `Event ${values.name} was created successfully`,
            type: NotificationType.SUCCESS
          }
        });
        // formik.resetForm();
        setCoverImage(undefined);
        dispatch({
          type: SET_ACTIVE_EVENT,
          payload: { event: response }
        });
        navigate(PATHS.event(response.uuid));
      } catch (e) {
        console.log(e);
      }
    }
  });

  useEffect(() => {
    if (eventTypes?.results?.[0]?.uuid && !formik.values.event_type_uuid) {
      formik.setFieldValue('event_type_uuid', eventTypes.results[0].uuid);
    }
  }, [eventTypes, formik]);

  const handlePriceChange = (event: any) => {
    const value = event.target.value.replace(/,/g, '');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (!isNaN(value)) {
      formik.setFieldValue('price', Number(value).toLocaleString());
    }
  };

  const { data: platformFee } = useSWR(
    `${API_CONFIG.PATH.PAYMENT.PLATFORM_FEE}`,
    () => httpService.get(API_CONFIG.PATH.PAYMENT.PLATFORM_FEE)(dispatch),
    swrOptions
  );

  if (!user.hasCompany) return null;

  return (
    <Container className="h-full">
      <Typography variant="h6" className="my-4">
        Create Event
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {coverImage && (
              <Box
                sx={{
                  height: 250,
                  width: 400,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden'
                }}
              >
                <img
                  src={coverImage.toString()}
                  alt="Cropped Cover"
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover'
                  }}
                />
              </Box>
            )}
            <Box sx={{ mt: 3 }}>
              <Button
                size="small"
                variant="outlined"
                className="capitalize"
                onClick={handleFilePickerClick}
                disabled={photoUpdating}
              >
                {photoUpdating ? (
                  <CircularProgress size={24} />
                ) : (
                  'Pick Cover Image'
                )}
              </Button>
              <br />
              <Typography variant="caption">
                Image should not exceed 900KB
              </Typography>
              <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />
            </Box>

            {/*<Modal*/}
            {/*  open={isCropModalOpen}*/}
            {/*  onClose={() => setIsCropModalOpen(false)}*/}
            {/*  aria-labelledby="crop-modal-title"*/}
            {/*  aria-describedby="crop-modal-description"*/}
            {/*>*/}
            {/*  <Box*/}
            {/*    sx={{*/}
            {/*      position: 'absolute',*/}
            {/*      top: '50%',*/}
            {/*      left: '50%',*/}
            {/*      transform: 'translate(-50%, -50%)',*/}
            {/*      width: '80%',*/}
            {/*      maxWidth: '600px',*/}
            {/*      bgcolor: 'background.paper',*/}
            {/*      boxShadow: 24,*/}
            {/*      p: 4,*/}
            {/*      borderRadius: 2*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <Typography*/}
            {/*      id="crop-modal-title"*/}
            {/*      variant="h6"*/}
            {/*      component="h2"*/}
            {/*      sx={{ mb: 2 }}*/}
            {/*    >*/}
            {/*      Crop Cover Image*/}
            {/*    </Typography>*/}
            {/*    <CropperArea*/}
            {/*      imageSrc={coverImage}*/}
            {/*      onCropComplete={handleCropComplete}*/}
            {/*      cropSize={{ width: 800, height: 450 }}*/}
            {/*      aspect={16 / 9}*/}
            {/*    />*/}
            {/*    <Box*/}
            {/*      sx={{*/}
            {/*        display: 'flex',*/}
            {/*        justifyContent: 'space-between',*/}
            {/*        mt: 2*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <Button*/}
            {/*        variant="outlined"*/}
            {/*        onClick={() => setIsCropModalOpen(false)}*/}
            {/*        disabled={photoSaving}*/}
            {/*      >*/}
            {/*        Cancel*/}
            {/*      </Button>*/}
            {/*      <Button*/}
            {/*        variant="contained"*/}
            {/*        onClick={saveCroppedImage}*/}
            {/*        disabled={photoSaving}*/}
            {/*      >*/}
            {/*        {photoSaving ? <CircularProgress size={24} /> : 'Save'}*/}
            {/*      </Button>*/}
            {/*    </Box>*/}
            {/*  </Box>*/}
            {/*</Modal>*/}
          </Grid>
          <Grid item xs={12} md={4}>
            <FormLabel>Event Name</FormLabel>
            <TextField
              name="name"
              placeholder="Event Name"
              fullWidth
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormLabel>Event Date</FormLabel>
            <TextField
              name="event_date"
              type="date"
              fullWidth
              value={formik.values.event_date}
              onChange={formik.handleChange}
              error={
                formik.touched.event_date && Boolean(formik.errors.event_date)
              }
              helperText={formik.touched.event_date && formik.errors.event_date}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormLabel>Event Type</FormLabel>
            <Select
              name="event_type_uuid"
              placeholder="Event Type"
              fullWidth
              value={formik.values.event_type_uuid}
              onChange={formik.handleChange}
              error={
                formik.touched.event_type_uuid &&
                Boolean(formik.errors.event_type_uuid)
              }
            >
              {eventTypes?.results?.map(
                (eventType: { uuid: string; name: string }) => (
                  <MenuItem key={eventType.uuid} value={eventType.uuid}>
                    {eventType.name}
                  </MenuItem>
                )
              )}
            </Select>
          </Grid>
          <Grid item xs={12} md={4} className="flex flex-col justify-end">
            <FormControlLabel
              control={
                <Checkbox
                  name="is_private"
                  checked={formik.values.is_private}
                  onChange={formik.handleChange}
                />
              }
              label="Private Event"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  name="is_premium"
                  checked={formik.values.is_premium}
                  onChange={formik.handleChange}
                />
              }
              label="Premium Event"
            />
          </Grid>
          {formik.values.is_premium && (
            <Grid item xs={12} className="flex flex-row" spacing={4}>
              <Grid item xs={12} md={4}>
                <FormLabel>Price</FormLabel>
                <TextField
                  name="price"
                  placeholder="Event Price"
                  fullWidth
                  value={formik.values.price}
                  onChange={handlePriceChange}
                  error={formik.touched.price && Boolean(formik.errors.price)}
                  helperText={formik.touched.price && formik.errors.price}
                />
                {formik.values.price && platformFee?.percentage && (
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="caption" color="text.secondary">
                      Platform fee ({(platformFee.percentage * 100).toFixed(1)}
                      %): {formik.values.currency}{' '}
                      {(
                        Number(formik.values.price.replace(/,/g, '')) *
                        platformFee.percentage
                      ).toLocaleString()}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="primary"
                      sx={{ fontWeight: 'medium' }}
                    >
                      You will receive: {formik.values.currency}{' '}
                      {(
                        Number(formik.values.price.replace(/,/g, '')) *
                        (1 - platformFee.percentage)
                      ).toLocaleString()}
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} md={4} className="ml-4">
                <FormLabel>Currency</FormLabel>
                <Select
                  name="currency"
                  placeholder="Currency"
                  value={formik.values.currency}
                  onChange={formik.handleChange}
                  fullWidth
                  readOnly
                  error={
                    formik.touched.currency && Boolean(formik.errors.currency)
                  }
                >
                  {currencyOptions?.map(
                    (eventType: { value: string; label: string }) => (
                      <MenuItem key={eventType.value} value={eventType.value}>
                        {eventType.label}
                      </MenuItem>
                    )
                  )}
                </Select>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Box className="flex flex-row justify-end mt-5">
          <Button
            type="submit"
            size="small"
            variant="contained"
            className="capitalize"
          >
            Create Event
          </Button>
        </Box>
      </form>
    </Container>
  );
};

CreateEventPage.propTypes = {};

export default CreateEventPage;
