import React from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from 'react-router-dom';
import NotFound from '../shared/components/errors/404';

import SignUp from '../pages/auth/signUp';
import Layout from '../layouts/main';
import SignIn from '../pages/auth/signIn';
import Dashboard from '../pages/dashboard';
import AuthLayout from '../layouts/auth/Index';

import CompanyProfile from '../pages/settings/index';
import EventDetails from '../pages/dashboard/event';
import PrivacyPolicy from '../pages/privacy-policy';
import RemoveAccount from '../pages/removeAccount';
import CreateEventPage from '../pages/dashboard/event/create';
import Subscriptions from '../pages/subscriptions';
import EventPhotos from '../pages/dashboard/event/photos';
import EventVideos from '../pages/dashboard/event/videos';
import StreamComponent from '../pages/dashboard/event/stream';
import MiniStatements from '../pages/dashboard/event/mini-statements';
import OnboardingFlow from '../pages/onboarding';
import FreeTrialPage from '../pages/subscriptions/free-trial';
import ProtectedRoute from '../routes/protected-route';

export const PATHS = {
  auth: {
    signIn: '/auth',
    signup: '/auth/signup'
  },
  dashboard: '/dashboard',
  createEvent: '/dashboard/event/create',
  event: (uuid: string) => `/dashboard/event/${uuid}`,
  settings: '/settings',
  onboarding: '/onboarding',
  freeTrial: '/free-trial',
  subscriptions: '/subscriptions'
};

export const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        {/* Auth Routes */}
        <Route
          path="/auth/*"
          element={
            <AuthLayout>
              <Routes>
                <Route path="/" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
              </Routes>
            </AuthLayout>
          }
        />

        {/* Main Layout Routes */}
        <Route
          path="/*"
          element={
            <Layout>
              <Routes>
                {/* Public Routes */}
                <Route path="onboarding" element={<OnboardingFlow />} />
                <Route path="free-trial" element={<FreeTrialPage />} />
                <Route path="subscriptions" element={<Subscriptions />} />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="remove_account" element={<RemoveAccount />} />

                {/* Protected Routes - Require Subscription/Trial */}
                <Route
                  path="dashboard"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="dashboard/event/create"
                  element={
                    <ProtectedRoute>
                      <CreateEventPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="dashboard/event/:uuid"
                  element={
                    <ProtectedRoute>
                      <EventDetails />
                    </ProtectedRoute>
                  }
                >
                  <Route index element={<EventPhotos />} />
                  <Route path="photos" element={<EventPhotos />} />
                  <Route path="videos" element={<EventVideos />} />
                  <Route path="stream" element={<StreamComponent />} />
                  <Route path="mini-statements" element={<MiniStatements />} />
                </Route>
                <Route
                  path="settings"
                  element={
                    <ProtectedRoute>
                      <CompanyProfile />
                    </ProtectedRoute>
                  }
                />

                {/* Default Route */}
                <Route path="" element={<Navigate to="dashboard" />} />
              </Routes>
            </Layout>
          }
        />

        {/* 404 Route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};
