import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State, NotificationType } from '../../shared/interface';
import httpService from '../../shared/services/http.service';
import { API_CONFIG } from '../../shared/constants/constants';
import { fetchCompanyDetails } from '../../store/actions/authActions';
import actionTypes from '../../store/action-types';
import { Stepper, Step, StepLabel, Container, Paper } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import CountryPhoneStep from './steps/CountryPhoneStep';
import CompanyDetailsStep from './steps/CompanyDetailsStep';
import DocumentsStep from './steps/DocumentsStep';
import ReviewStep from './steps/ReviewStep';

const steps = [
  'Location & Contact',
  'Company Information',
  'Documentation',
  'Review'
];

const OnboardingFlow: React.FC = () => {
  const { user } = useSelector((state: State) => state.auth);
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const dispatch = useDispatch();

  const checkAndUpdateProgress = useCallback(async () => {
    try {
      // Only fetch company details if user has a company
      if (user?.hasCompany) {
        const company = await httpService.get(API_CONFIG.PATH.COMPANY.GET)(
          dispatch,
          true
        );

        // Get completed steps based on field validation
        const validatedSteps = checkStepCompletion(company);
        setCompletedSteps(validatedSteps);

        // Set active step to first incomplete step
        const firstIncompleteStep = [0, 1, 2, 3].find(
          step => !validatedSteps.includes(step)
        );
        setActiveStep(firstIncompleteStep ?? validatedSteps.length - 1);
      } else {
        // New user - start from beginning
        setActiveStep(0);
        setCompletedSteps([]);
      }
    } catch (error) {
      // Only show error if user has company but fetch failed
      if (user?.hasCompany) {
        dispatch({
          type: actionTypes.SET_NOTIFICATION,
          payload: {
            message: 'Failed to load onboarding progress',
            type: NotificationType.ERROR
          }
        });
      }
    }
  }, [dispatch, user]);

  useEffect(() => {
    checkAndUpdateProgress().then();
  }, [checkAndUpdateProgress]);

  const checkStepCompletion = (company: any) => {
    const validatedSteps: number[] = [];

    // Check Step 1: Location & Contact
    if (company.country && company.support_number) {
      validatedSteps.push(0);
    }

    // Check Step 2: Company Information
    if (company.name && company.location && company.currency) {
      validatedSteps.push(1);
    }

    // Check Step 3: Documentation
    if (
      company.company_document_one ||
      company.company_document_two ||
      company.company_document_three
    ) {
      validatedSteps.push(2);
    }

    // Check Step 4: Review (if status is SUBMITTED)
    if (company.status === 'SUBMITTED') {
      validatedSteps.push(3);
    }

    return validatedSteps;
  };

  const handleNext = async () => {
    try {
      // Update progress on server
      await httpService.patch(API_CONFIG.PATH.COMPANY.UPDATE, {
        onboarding_step: activeStep + 1,
        completed_steps: [...completedSteps, activeStep]
      })(dispatch, true);

      // Update local state
      setCompletedSteps(prev => [...prev, activeStep]);
      setActiveStep(prev => prev + 1);

      // Refresh company details to sync with server
      await fetchCompanyDetails(dispatch);
    } catch (error) {
      dispatch({
        type: actionTypes.SET_NOTIFICATION,
        payload: {
          message: 'Failed to save progress',
          type: NotificationType.ERROR
        }
      });
    }
  };

  // Clear storage when onboarding is complete
  useEffect(() => {
    if (user?.company?.status === 'PENDING') {
      localStorage.removeItem('onboardingStep');
      localStorage.removeItem('completedSteps');
    }
  }, [user?.company?.status]);

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <CountryPhoneStep onNext={handleNext} />;
      case 1:
        return <CompanyDetailsStep onNext={handleNext} />;
      case 2:
        return <DocumentsStep onNext={handleNext} />;
      case 3:
        return <ReviewStep />;
      default:
        return null;
    }
  };

  return (
    <Container maxWidth="md">
      <Paper
        elevation={3}
        sx={{
          p: { xs: 2, md: 6 },
          mt: 4,
          borderRadius: 3,
          minHeight: '75vh',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Stepper
          activeStep={activeStep}
          sx={{
            mb: 6,
            '& .MuiStepLabel-root .Mui-completed': {
              color: 'primary.main'
            },
            '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
              {
                color: 'primary.main'
              },
            '& .MuiStepLabel-root .Mui-active': {
              color: 'secondary.main'
            }
          }}
        >
          {steps.map((label, index) => (
            <Step key={label} completed={completedSteps.includes(index)}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <AnimatePresence mode="wait">
          <motion.div
            key={activeStep}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.4, ease: 'easeInOut' }}
            style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
          >
            {renderStepContent(activeStep)}
          </motion.div>
        </AnimatePresence>
      </Paper>
    </Container>
  );
};

export default OnboardingFlow;
