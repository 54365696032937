import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import httpService from '../../shared/services/http.service';
import { API_CONFIG } from '../../shared/constants/constants';
import {
  fetchCompanyDetails,
  setAuthenticated,
  setTokens
} from '../../store/actions/authActions';

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onGoogleSignIn = async ({ credential }: CredentialResponse) => {
    const response = await httpService.post(API_CONFIG.PATH.GOOGLE_AUTH, {
      auth_token: credential,
      user_type: 'B2B'
    })(dispatch, true);

    dispatch(setTokens(response.tokens));
    dispatch(
      setAuthenticated({
        uuid: response.uuid,
        email: response.email,
        username: response.username,
        hasCompany: response.has_company,
        authProvider: response.auth_provider
      })
    );

    if (response.has_company) {
      const companyDetails = await fetchCompanyDetails(dispatch);

      // Check company status first
      if (companyDetails.status === 'PENDING') {
        navigate('/onboarding');
        return;
      }

      // Check subscription and trial status
      if (!companyDetails.has_active_subscription) {
        if (!companyDetails.has_used_trial) {
          navigate('/free-trial');
        } else {
          navigate('/subscriptions', { state: { from: 'none' } });
        }
        return;
      }

      // If everything is good, navigate to dashboard
      navigate('/dashboard');
    } else {
      navigate('/onboarding', { state: { from: 'none' } });
    }
  };

  const OnGoogleSignInFailure = () => {
    console.error('login failed');
  };

  return (
    <Paper elevation={3} className="p-12 max-w-xl w-full mx-4 rounded-xl">
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Typography
            variant="h4"
            component="h4"
            className="text-center font-bold text-gray-800"
            sx={{ letterSpacing: '-0.5px' }}
          >
            Welcome to Pixsar Media
          </Typography>
          <Typography
            variant="h5"
            className="text-center mt-3 text-gray-600 font-medium"
          >
            Elevate Your Media Business
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            variant="body1"
            className="text-center text-gray-600 leading-relaxed"
          >
            As a photographer, videographer, or media company, gain access to
            powerful tools that help you reach a wider audience, increase client
            engagement, and drive more bookings and revenue.
          </Typography>
        </Grid>

        <Grid item className="flex justify-center pt-4">
          <Box className="transform hover:scale-105 transition-transform duration-200">
            <GoogleLogin
              onSuccess={onGoogleSignIn}
              onError={OnGoogleSignInFailure}
              text="signin_with"
              logo_alignment="center"
              cancel_on_tap_outside={true}
              auto_select={true}
              theme="filled_blue"
            />
          </Box>
        </Grid>

        <Grid item>
          <Typography
            variant="body2"
            className="text-center text-gray-500 mt-6"
          >
            By continuing, you agree to our <br />
            <span className="text-blue-600 cursor-pointer hover:underline">
              Terms & Conditions
            </span>{' '}
            and{' '}
            <span className="text-blue-600 cursor-pointer hover:underline">
              Privacy Policy
            </span>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SignIn;
